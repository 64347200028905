// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconDashboard, IconDeviceAnalytics, IconUser } from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconUser,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Profile = {
  id: "User",
  title: <FormattedMessage id="Dashboard" />,
  icon: icons.IconDashboard,
  type: "group",
  children: [
    // {
    //   id: "My Profile",
    //   title: <FormattedMessage id="My Profile" />,
    //   type: "item",
    //   url: "/profile",
    //   icon: icons.IconUser,
    //   breadcrumbs: false,
    // },
    {
      id: "My Dashboard",
      title: <FormattedMessage id="Dashboard" />,
      type: "item",
      url: "/user/dashboard",
      icon: icons.IconDeviceAnalytics,
      breadcrumbs: false,
    },
  ],
};

export default Profile;
