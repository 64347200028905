import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "ui-component/Loader";
import axios from "utils/axios";
import Axios from "axios";
import { API_URL, APP_URL } from "config";

const SERVER_API_URL = API_URL;
const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }

  const decoded = jwtDecode(serviceToken);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    localStorage.removeItem("users");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get("/api/account/me");
          const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email, password, RememberMe) => {
    if (RememberMe === true) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    }
    if (RememberMe === false) {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    const response = await Axios.post(SERVER_API_URL + "/api/auth/signin", {
      email,
      password,
    });

    if (response.data.status !== "200") {
      console.log(response.data.data, "response.data.data");
      return response.data;
    } else if (response.data.status === "200") {
      console.log(response, "response");
      const serviceToken = response.data.data.accesstoken;

      setSession(serviceToken);
      let user = {
        id: response.data.data.id,
        email: response.data.data.email,
        name: response.data.data.username,
        groupId: response.data.data.groupId,
        role_id: response.data.data.role_id,
        CurrentEventName: response.data.data.CurrentEventName,
      };
      window.localStorage.setItem(
        "CurEvent",
        response.data.data.CurrentEventName
      );
      window.localStorage.setItem("users", JSON.stringify(user));
      if (user.role_id == 1) {
        window.location.href = "/User_Management";
      } else if (user.role_id == 0) {
        window.location.href = "/user/dashboard";
      }
      /* dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
      }); */

      return response.data;
    }
  };

  const delay = (timeout) => new Promise((res) => setTimeout(res, timeout));

  const register = async (
    email,
    password,
    firstName,
    lastName,
    mobile,
    confirmPassword,
    AddCountryDialCode
  ) => {
    try {
      await delay(500);

      if (!email || !password) {
        return [400, { message: "Enter Your Email & Password" }];
      }

      if (!firstName || !lastName) {
        return [400, { message: "Enter Your Name" }];
      }

      const id = chance.bb_pin();

      const response = await Axios.post(SERVER_API_URL + "/api/auth/signup", {
        id,
        email,
        password,
        firstName,
        lastName,
        mobile,
        AddCountryDialCode,
      });

      return response.data;
    } catch (err) {
      return [500, { message: "Server Error" }];
    }
  };

  const registerOtpVerify = async (regUserId, otp) => {
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/registerotpverify", {
      id,
      regUserId,
      otp,
    });

    return response.data;
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email) => {
    try {
      const ServerResponseNext = await Axios.post(
        SERVER_API_URL + "/api/account/forgot",
        { email }
      );

      let userRes = [];
      if (
        ServerResponseNext.data.status &&
        ServerResponseNext.data.status === "200"
      ) {
        userRes = [200, ServerResponseNext.data.data];
      } else {
        userRes = [201, ServerResponseNext.data];
      }
      return userRes;
    } catch (err) {
      console.error(err);
      return [500, { message: "Server Error" }];
    }
  };

  const verifyotp = async (UserId, otp) => {
    try {
      const ServerResponseNext = await Axios.post(
        SERVER_API_URL + "/api/account/forgot/verifyotp",
        { UserId, otp }
      );

      let userRes = [];
      if (
        ServerResponseNext.data.status &&
        ServerResponseNext.data.status === "200"
      ) {
        userRes = [200, ServerResponseNext.data];
      } else {
        userRes = [201, ServerResponseNext.data];
      }
      return userRes;
    } catch (err) {
      console.error(err);
      return [500, { message: "Server Error" }];
    }
  };

  const newPassword = async (UserId, Password) => {
    try {
      const ServerResponseNext = await Axios.post(
        SERVER_API_URL + "/api/auth/reset_password",
        { UserId, Password }
      );

      let userRes = [];
      if (
        ServerResponseNext.data.status &&
        ServerResponseNext.data.status === "200"
      ) {
        userRes = [200, ServerResponseNext.data];
      } else {
        userRes = [201, ServerResponseNext.data];
      }
      return userRes;
    } catch (err) {
      console.error(err);
      return [500, { message: "Server Error" }];
    }
  };
  const Profiledata = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/profile_view",
      { userId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const SociallinkView = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/Sociallink_view",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const updateProfile = async (
    fname,
    lname,
    city,
    tradingviewIid,
    address,
    occupation,
    userId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/profile_update",
      {
        fname,
        lname,
        city,
        tradingviewIid,
        address,
        occupation,
        userId,
      }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const updateSociallink = async (
    about,
    instaLink,
    fbLink,
    twitterLink,
    linkedinLink,
    userId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/Sociallinkupdate",
      {
        about,
        instaLink,
        fbLink,
        twitterLink,
        linkedinLink,
        userId,
      }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const AllUsers = async (
    userId,
    search,
    GroupFilterstate,
    searchfilterstate
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    // alert(searchfilterstate + 'i am jwt');
    const response = await Axios.post(SERVER_API_URL + "/api/user/AllUsers", {
      userId,
      search,
      GroupFilterstate,
      searchfilterstate,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const Addfriend = async (userId, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/follow_member", {
      userId,
      followUserId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const Removefriend = async (userId, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/unfollow_member", {
      userId,
      followUserId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const MyFollower = async (userId, search, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/followers_list", {
      userId,
      search,
    });
    verifyAuthorization(response);
    return response;
  };

  const MyFriends = async (userId, search, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/following_list", {
      userId,
      search,
    });
    verifyAuthorization(response);
    return response;
  };

  const MyGrouplist = async (userId, groupId, search) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/mygroup_members_list",
      {
        userId,
        groupId,
        search,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const Statistics = async (userId, memberId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/members_statistics",
      {
        userId,
        memberId,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const MemberProfile = async (userId, memberId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/view_member_profile",
      {
        userId,
        memberId,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Qscore = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Qscore", {
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const Rewards_chart = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/rewardschart",
      { userId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const watchlist = async (watchType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/watch_list", {
      watchType,
    });
    verifyAuthorization(response);
    return response;
  };

  const corewatchlist = async (watchType) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/getcompanylistCore",
      { watchType }
    );
    return response;
  };
  const coreInsertwatchlist = async (
    companyId,
    userId,
    watchtype,
    currentDate,
    currentyear,
    role_id
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/coreInsertwatchlist",
      {
        companyId,
        userId,
        watchtype,
        currentDate,
        currentyear,
        role_id,
      }
    );

    return response;
  };
  const corewatchlisthistory = async (userId, weeknum, CurrentYear) => {
    console.log("set", userId, weeknum, CurrentYear);
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/Getdata ",
      {
        userId,
        weeknum,
        CurrentYear,
      }
    );

    return response;
  };

  const DeleteCoreWatchlist = async (wid, companyid) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/deletecorewatchlisthistory",
      {
        wid,
        companyid,
      }
    );
  };

  const EditCoreWatchlistgetdata = async (watchId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/EditGetdata",
      {
        watchId,
      }
    );
    return response;
  };

  const Insertwatchlist = async (
    companyId,
    userId,
    watchtype,
    currentDate,
    currentyear
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/addwatchlist",
      {
        companyId,
        userId,
        watchtype,
        currentDate,
        currentyear,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const weekly = async (userId, watchType, weeknum) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/weekly", {
      userId,
      watchType,
      weeknum,
    });
    // const { serviceToken, user } = response.data;
    verifyAuthorization(response);
    return response;
  };

  const weeklist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/weeklist", {
      userId,
    });
    // const { serviceToken, user } = response.data;
    verifyAuthorization(response);
    return response;
  };

  const DeleteWatchlist = async (userId, wid, companyid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/deletewatchlisthistory",
      {
        userId,
        wid,
        companyid,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Resourceslist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Resources", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };
  const ResearchList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Research", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const TemplateList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Templates", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const TechnoList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Techno", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };
  const ApplicationsList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/apps", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const GetLearing = async (userId, topicType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/GetLearing", {
      userId,
      topicType,
    });
    verifyAuthorization(response);
    return response;
  };

  const DeleteLearningHistory = async (userId, topicid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/LearnPath/DeleteLearningHistory",
      { userId, topicid }
    );
    verifyAuthorization(response);
    return response;
  };

  const InsertLearningHistory = async (userId, topicid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/InsertLearningHistory",
      { userId, topicid }
    );
    verifyAuthorization(response);
    return response;
  };

  const CheckTotalCountOfLearning = async (userId, topicType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/LearnPath/CheckTotalCountOfLearning",
      { userId, topicType }
    );
    verifyAuthorization(response);
    return response;
  };

  const CheckStatusOfLearning = async (userId, topicType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/CheckStatusOfLearning",
      { userId, topicType }
    );
    verifyAuthorization(response);
    return response;
  };

  const Alloccupationlist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/Profile/Alloccupationlist",
      {
        userId,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const ProcessUpdate = async (profileProcess, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/Profile/processUpdate",
      {
        profileProcess,
        userId,
      }
    );
    verifyAuthorization(response);
    return response.data;
  };
  const watchlistdata = async (userId, weeknum, CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/getwatchlisthistory",
      {
        userId,
        weeknum,
        CurrentYear,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const vivekwatchlistdata = async (userId, weeknum, CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/getvivekwatchlisthistory",
      {
        userId,
        weeknum,
        CurrentYear,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const CoreauditCashdatalist = async (userId, quarterly, CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/getcoreauditCash",
      { userId, quarterly, CurrentYear }
    );
    verifyAuthorization(response);
    return response;
  };

  const UpdateCoreauditCashdata = async (
    userId,
    quarterly,
    curyear,
    onInvesting,
    onCurrent,
    returnVal,
    comment
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/updatecoreauditCash",
      {
        userId,
        quarterly,
        curyear,
        onInvesting,
        onCurrent,
        returnVal,
        comment,
      }
    );

    verifyAuthorization(response);
    return response;
  };

  const Coreauditcompanylist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/getcoreauditcompanylist",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const Coreauditdatalist = async (userId, quarterly, CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/getcoreaudit",
      { userId, quarterly, CurrentYear }
    );
    verifyAuthorization(response);
    return response;
  };

  const updateCoreAudit = async (
    onInvesting,
    onCurrent,
    returnVal,
    comment,
    HP,
    userId,
    companyId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/coreauditupdate",
      {
        onInvesting,
        onCurrent,
        returnVal,
        comment,
        HP,
        userId,
        companyId,
      }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const insertCoreAudit = async (userId, companyId, quarterly) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/coreauditinsert",
      { userId, companyId, quarterly }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const deleteCoreAudit = async (userId, companyId, quarterly) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/coreauditDelete",
      { userId, companyId, quarterly }
    );
    verifyAuthorization(response);
    return response.data;
  };

  // Rituals
  const WeeklyRitualsQuestions = async (Status1, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/weeklyritualsquestions",
      {
        Status1,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const WeeklyRitualsAnswer = async (data, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/weeklyritualsquestions_answers",
      {
        data,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const WeeklyRitualsAnswerCheck = async (User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/weeklyritualsquestions_answers_check",
      {
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const QuarterlyRitualsQuestions = async (Status1, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/quarterlyritualsquestions",
      {
        Status1,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const QuarterlyRitualsAnswer = async (data, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/quarterlyritualsquestions_answers",
      {
        data,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const QuarterlyRitualsAnswerCheck = async (User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/quarterlyritualsquestions_answers_check",
      {
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  //Rituals

  const Quantum_Quiz_Questions = async (Status1, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Quantum", {
      Status1,
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const Quantum_insertdata = async (userId, question, email) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/Quantum_insertdata",
      { userId, question, email }
    );
    verifyAuthorization(response);
    return response;
  };
  const checkuserIddata = async (userId, weekNumber) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/checkuserIddata",
      { userId, weekNumber }
    );
    verifyAuthorization(response);
    return response;
  };
  const checkanswerdata = async (userId, weekNumber) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/checkanswerdata",
      { userId, weekNumber }
    );
    verifyAuthorization(response);
    return response;
  };
  const charitylistdata = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/charity_listdata",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const deletecharitydata = async (nameCom, userId, month, amount) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/delete_charitylistdata",
      { nameCom, userId, month, amount }
    );
    verifyAuthorization(response);
    return response;
  };
  const Insertcharitylist = async (
    charityid,
    userId,
    year,
    month,
    amount,
    addedon,
    monthid
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/insertcharity_list",
      { charityid, userId, year, month, amount, addedon, monthid }
    );
    verifyAuthorization(response);
    return response;
  };
  const charitylist = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/charity_list",
      {}
    );
    verifyAuthorization(response);
    return response;
  };
  const Editcharitydata = async (
    charityListid,
    year1,
    month,
    amount,
    charityList,
    userId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/editcharitydata",
      { charityListid, year1, month, amount, charityList, userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const weekdata1 = async (charityid, userid, watchtype) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/weekdata1", {
      charityid,
      userid,
      watchtype,
    });
    // const { serviceToken, user } = response.data;
    verifyAuthorization(response);
    return response;
  };

  const Email = async (userId) => {
    try {
      const serviceToken = window.localStorage.getItem("serviceToken");
      Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
      const response = await Axios.post(SERVER_API_URL + "/api/user/Email", {
        userId,
      });
      verifyAuthorization(response);
      return response.data;
    } catch (err) {
      return [500, { message: "Server Error" }];
    }
  };

  const TopTwentyuserDetailsBaseOnRewaerdPoint = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/TopTwentyuserDetailsBaseOnRewaerdPoint",
      {}
    );
    verifyAuthorization(response);
    return response;
  };

  const TrendingCorePortfolio = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/TrendingCorePortfolio",
      {}
    );
    verifyAuthorization(response);
    return response;
  };

  const TrendingSatelliteWatchlist = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/TrendingSatelliteWatchlist",
      {}
    );
    verifyAuthorization(response);
    return response;
  };

  const RewardsPoint = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/RewardsPoint", {
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const Pastevent = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/event/Pastevent", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };
  const Futurevent = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/Futurevent",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const Todayevent = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/Todayevent",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const GetAttendance = async (userId, groupId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/Attendance",
      { userId, groupId }
    );
    verifyAuthorization(response);
    return response;
  };
  const InsertAttendance = async (userId, scheduleId, eventId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/InsertEventTracking",
      { userId, scheduleId, eventId }
    );
    verifyAuthorization(response);
    return response;
  };
  const AttendanceChartData = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/AttendanceChartData",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const updateVideoLink = async (
    userId,
    eventId,
    EditVideoLink,
    EditVideoLink2,
    EditVideoCompanyName1,
    EditVideoCompanyName2,
    EditVideoPassword1,
    EditVideoPassword2
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/updatevideolink",
      {
        userId,
        eventId,
        EditVideoLink,
        EditVideoLink2,
        EditVideoCompanyName1,
        EditVideoCompanyName2,
        EditVideoPassword1,
        EditVideoPassword2,
      }
    );
    verifyAuthorization(response);
    return response.data;
  };
  const CalendarEventsList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/CalendarEventsList",
      { userId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const profilevisibilityInsert = async (userId, module) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/profile_visibility_insert",
      { userId, module }
    );
    verifyAuthorization(response);
    return response;
  };

  const profilevisibility = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/profile_visibility",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const UserDetails = async (userId, search, GroupFilterstate) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/userdetail",
      { userId, search, GroupFilterstate }
    );
    verifyAuthorization(response);
    return response;
  };
  const PropertyDetails = async (userId, search, GroupFilterstate) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/propertyManagement/propertydetails",
      { userId, search, GroupFilterstate }
    );
    verifyAuthorization(response);
    return response;
  };
  const LeadsDetails = async (userId, search, GroupFilterstate,startPickDate,endPickDate,getpropertyId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/leadsManagement/leadsdetails",
      { userId, search, GroupFilterstate,startPickDate,endPickDate,getpropertyId }
    );
    verifyAuthorization(response);
    return response;
  };
  const LeadsDetailsDashboard = async (
    userId,
    uId,
    startPickdate,
    endPickdate
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/leadsManagement/leadsdetailsuserdashboard",
      { userId, uId, startPickdate, endPickdate }
    );
    verifyAuthorization(response);
    return response;
  };

  const Editdatauser = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/Editdatauser",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditdataProperty = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/property_management/EditdataProperty",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditSavedata = async (
    userId,
    ValueCheck,
    emaildata,
    fnamedata,
    lastnamdata,
    Phonenumber,
    Apikey,
    srdKey
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/Editsavedata",
      {
        userId,
        ValueCheck,
        emaildata,
        fnamedata,
        lastnamdata,
        Phonenumber,
        Apikey,
        srdKey,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditSavedataProperty = async (
    userId,
    ValueCheck,
    pId,
    pName,
    pLink,
    pOwnerName
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/EditsavedataProperty",
      {
        userId,
        ValueCheck,
        pId,
        pName,
        pLink,
        pOwnerName,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Adddatauser = async (
    userId,
    Addfnamedata,
    Addlnamedata,
    Addemaildata,
    Addmobilenumberdata,
    AddCitydata,
    Addaddressdata,
    addApikey,
    addSrdkey
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/Adddatauser",
      {
        userId,
        Addfnamedata,
        Addlnamedata,
        Addemaildata,
        Addmobilenumberdata,
        AddCitydata,
        Addaddressdata,
        addApikey,
        addSrdkey,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const AdddataProperty = async (
    userId,
    propertyName,
    propertyOwner,
    propertyLink,
    addpropertyId
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/propertyManagement/Adddataproperty",
      {
        userId,
        propertyName,
        propertyOwner,
        propertyLink,
        addpropertyId,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const AdddataLeads = async (userId, name, phone, email, propertyId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/propertyManagement/Adddatapropertyleads",
      {
        userId,
        name,
        phone,
        email,
        propertyId,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Csvreport = async (userId, module, date) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/Csvreport",
      { userId, module, date }
    );
    return response;
  };

  const CharityReport = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/CharityReport",
      { userId, value }
    );
    return response;
  };
  const Watchlist = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/SatelliteWatchListReport",
      { userId, value }
    );
    return response;
  };

  const LoginReport = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/LoginReport",
      { userId, value }
    );
    return response;
  };

  const QuantumQuizdata = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/QuantumQuiz",
      { userId, value }
    );
    return response;
  };
  const NotloginReportdata = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/NotLoginReport",
      { userId, value }
    );
    return response;
  };
  const CoreAuditReportdata = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/CoreAuditReport",
      { userId, value }
    );
    return response;
  };

  const EventList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/event/EventList", {
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const GroupnameList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/GroupnameList",
      { userId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const AddEvent = async (
    Event_Start,
    Event_End,
    Description,
    Event_Name,
    Link,
    Mission,
    userId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/event/AddEvent", {
      Event_Start,
      Event_End,
      Description,
      Event_Name,
      Link,
      Mission,
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const GetEventsList = async (userId, search, FilterGroup, timeline) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/GetEventsList",
      { userId, search, FilterGroup, timeline }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const DeleteSchedule = async (userId, Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/DeleteSchedule",
      { userId, Id }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const ListofPropertyOwner = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.get(
      SERVER_API_URL + "/api/listofpropertyowner"
    );
    verifyAuthorization(response);
    return response.data;
  };
  const ListofProperty = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.get(SERVER_API_URL + "/api/listofproperty");
    verifyAuthorization(response);
    return response.data;
  };

  const DeletePropertyTable = async (userId, Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/propertyManagement/deleteproperty",
      { userId, Id }
    );
    verifyAuthorization(response);
    return response.data;
  };
  const DeleteUserTable = async (userId, Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/userManagement/deleteuser",
      { userId, Id }
    );
    verifyAuthorization(response);
    return response.data;
  };
  const DeletePropertyLeads = async (userId, Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/propertyManagement/deletepropertyleads",
      { userId, Id }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const DeleteAllSchedule = async (userId, EventId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/DeleteAllSchedule",
      { userId, EventId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const updateEvent = async (
    userId,
    EditEvent_id,
    EditEvent_End,
    EditDescription,
    EditMission,
    EditLink,
    EditEvent_Start,
    EditEvent_Name
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/updateEvent",
      {
        userId,
        EditEvent_id,
        EditEvent_End,
        EditDescription,
        EditMission,
        EditLink,
        EditEvent_Start,
        EditEvent_Name,
      }
    );
    verifyAuthorization(response);
    return response.data;
  };
  //stock
  const stockInsert = async (
    userId,
    alert_name,
    companyId,
    stop_loss,
    profit_target,
    expiry_date
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/stockInsert",
      { userId, alert_name, companyId, stop_loss, profit_target, expiry_date }
    );
    verifyAuthorization(response);
    return response;
  };
  const stocklist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/stocklist",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const StockAlertList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/StockAlertList",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const stockalertUpdate = async (
    alert_id,
    stop_loss,
    expiry_date,
    profit_target,
    alert_name,
    status
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/stockalertUpdate",
      { alert_id, stop_loss, expiry_date, profit_target, alert_name, status }
    );
    verifyAuthorization(response);
    return response;
  };

  const stockalertDelete = async (alert_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/stockalertDelete",
      { alert_id }
    );
    verifyAuthorization(response);
    return response;
  };
  const stockalertviewdata = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/stockChart", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const Getcategory = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/getcategorylist", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const ContactInsert = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    files,
    ext
  ) => {
    const data = new FormData();
    let response = "";
    let fileName = "";
    if (files.length !== 0) {
      data.append("file", files[0]);
      const ext = files[0].name.substring(files[0].name.lastIndexOf(".") + 1);

      if (ext === "pdf" || ext === "png" || ext === "jpg" || ext === "jpeg") {
        const upload = await Axios.post(
          SERVER_API_URL + "/uploadAttachment",
          data
        ).then((upload) => {
          fileName = upload.data[0].filename;
          response = InsertWithLogin(
            userId,
            category,
            email,
            mobile,
            subject,
            content,
            fileName
          );
        });
      }
      return response;
    } else {
      response = InsertWithLogin(
        userId,
        category,
        email,
        mobile,
        subject,
        content,
        fileName
      );
      //  verifyAuthorization(response);
      return response;
    }
  };
  const GetcategoryWithoutLogin = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/getcategorylistWithoutlogin",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const ContactInsertWithoutLogin = async (
    userId,
    category,
    email,
    mobile,
    AddCountryDialCode,
    subject,
    content,
    files
  ) => {
    const data = new FormData();
    let response = "";
    let fileName = "";
    if (files.length !== 0) {
      data.append("file", files[0]);
      const ext = files[0].name.substring(files[0].name.lastIndexOf(".") + 1);
      if (ext === "pdf" || ext === "png" || ext === "jpg" || ext === "jpeg") {
        const upload = await Axios.post(
          SERVER_API_URL + "/uploadAttachment",
          data
        ).then((upload) => {
          fileName = upload.data[0].filename;
          response = Insert(
            userId,
            category,
            email,
            mobile,
            AddCountryDialCode,
            subject,
            content,
            fileName
          );
        });
      }
      return response;
    } else {
      response = Insert(
        userId,
        category,
        email,
        mobile,
        AddCountryDialCode,
        subject,
        content,
        fileName
      );

      return response;
    }
  };

  const Insert = async (
    userId,
    category,
    email,
    mobile,
    AddCountryDialCode,
    subject,
    content,
    fileName
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/contactInsertWithoutlogin",
      {
        userId,
        category,
        email,
        mobile,
        AddCountryDialCode,
        subject,
        content,
        fileName,
      }
    );

    return response;
  };

  const EventReport = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/EventlatestReport",
      { userId, value }
    );
    return response;
  };

  const AlertReport = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/AlertReport",
      { userId, value }
    );
    return response;
  };

  const InsertWithLogin = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    fileName
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/contactInsert", {
      userId,
      category,
      email,
      mobile,
      subject,
      content,
      fileName,
    });

    verifyAuthorization(response);
    return response;
  };
  const GetdataHelpManagement = async (userId, filter, search, category) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/contact/GetContactData",
      { userId, filter, search, category }
    );
    verifyAuthorization(response);
    return response;
  };

  const GetcategorylistTool = async (userId, filter, search) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/getcategorylistTool",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const UpdateResponse = async (message, PrimaryId, status, userId, email) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/UpdateResponse", {
      message,
      PrimaryId,
      status,
      userId,
      email,
    });
    verifyAuthorization(response);
    return response;
  };
  const Displayresponse = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Displayresponse", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const InsertHabit = async (date, habit_id, users_Id, freq, Year, Month) => {
    console.log(date, habit_id, users_Id, freq, Year);
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/InsertHabit",
      { date, habit_id, users_Id, freq, Year, Month }
    );
    return response;
  };

  const GetHabitsList = async (curmonth) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/GetHabitsList",
      { curmonth }
    );
    return response;
  };
  const Gethabitsdata = async (curmonth, curyear, user_id) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/Gethabitsdata",
      { curmonth, curyear, user_id }
    );
    return response;
  };

  const handleChangedeletedata = async (habit_id) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/Habitdeletedata",
      { habit_id }
    );
    return response;
  };
  const habits = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/habit/habits");
    verifyAuthorization(response);
    return response.data;
  };
  const habitchart = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/habitcharts"
    );
    verifyAuthorization(response);
    return response.data;
  };
  const CheckStatusOfHabit = async (curyear, curmonth, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/HabitsChart",
      { curyear, curmonth, userId }
    );
    verifyAuthorization(response);
    return response.data;
  };
  const MonthlyLineChart = async (curyear, curmonth, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/HabitsMonthChart",
      { curyear, curmonth, userId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const GetHabitsNamelist = async (UserId, Month, Year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/GetHabitsNamelist",
      { UserId, Month, Year }
    );
    verifyAuthorization(response);
    return response;
  };
  const CatcusDeleteCoreWatchlist = async (wid, companyid) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/Catcusdeletecorewatchlisthistory",
      {
        wid,
        companyid,
      }
    );
  };

  const Catcuswatchlisthistory = async (userId, weeknum, CurrentYear) => {
    console.log("set", userId, weeknum, CurrentYear);
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/CatcusWatchlistGetdata",
      {
        userId,
        weeknum,
        CurrentYear,
      }
    );

    return response;
  };

  const GetAllCompanyforStockAlert = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/GetStockalertcompany"
    );
    verifyAuthorization(response);
    return response;
  };

  // const CreateStockalert = async (alert_name,userId,companyId,profit_target,stop_loss,expiry_date) => {
  //   const serviceToken = window.localStorage.getItem("serviceToken");
  //   axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  //   const response = await Axios.post(SERVER_API_URL+ "/api/stockAlert/CreateStockalert",{alert_name,userId,companyId,profit_target,stop_loss,expiry_date});
  //   verifyAuthorization(response);
  //   return response;
  // };
  const CreateStockalert = async (
    alert_name,
    userId,
    companyId,
    profit_target,
    stop_loss_per,
    profit_target_per,
    stop_loss,
    expiry_date
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/CreateStockalert",
      {
        alert_name,
        userId,
        companyId,
        profit_target,
        stop_loss_per,
        profit_target_per,
        stop_loss,
        expiry_date,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const GetStockalertCount = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/GetStockalertCount",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const GetStockalertList = async (userId, status) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/GetStockalertList",
      { userId, status }
    );
    verifyAuthorization(response);
    return response;
  };

  const StockAlertDelete = async (id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/alertDelete",
      { id }
    );
    verifyAuthorization(response);
    return response;
  };

  // const UpdateStockAlert = async (id,stop_loss,expiry_date,profit_target,alert_name) => {
  //   const serviceToken = window.localStorage.getItem("serviceToken");
  //   axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  //   const response = await Axios.post(SERVER_API_URL+ "/api/stockAlert/UpdateStockAlert",{id,stop_loss,expiry_date,profit_target,alert_name});
  //   verifyAuthorization(response);
  //   return response;
  // };
  const GetMarketcategory = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/market/GetMarketcategory",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const GetMarketchartdata = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/market/chartdata",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const UpdateStockAlert = async (
    id,
    stop_loss,
    stop_loss_per,
    expiry_date,
    profit_target,
    profit_target_per,
    alert_name
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/UpdateStockAlert",
      {
        id,
        stop_loss,
        stop_loss_per,
        expiry_date,
        profit_target,
        profit_target_per,
        alert_name,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  function verifyAuthorization(response) {
    if (response.data.statuscode === 401) {
      logout();
      window.location.href = APP_URL + "/login";
    } else {
      return true;
    }
  }

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  const Gamefications = async (days) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Gamefication", {
      days,
    });
    verifyAuthorization(response);
    return response;
  };

  const Onboarding = async (userId, groupId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/OnBoarding", {
      userId,
      groupId,
    });
    verifyAuthorization(response);
    return response;
  };

  const Marketdata = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/get_nse_marketdata");
    verifyAuthorization(response);
    return response;
  };

  const Insertedata = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/nse_marketdata");
    verifyAuthorization(response);
    return response;
  };
  const UserDetailsQic = async (
    userId,
    search,
    SEmail,
    SHackothonFinisher,
    STechnofundaAward,
    SGroup,
    SCommunityDuration,
    SIndustry,
    STeamName,
    STeamPosition,
    GroupFilterstate
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/userdetailQic",
      {
        userId,
        search,
        SEmail,
        SHackothonFinisher,
        STechnofundaAward,
        SGroup,
        SCommunityDuration,
        SIndustry,
        STeamName,
        STeamPosition,
        GroupFilterstate,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const MentorDetailsQic = async (
    userId,
    search,
    SEmail,
    SHackothonFinisher,
    STechnofundaAward,
    SGroup,
    SCommunityDuration,
    SIndustry,
    STeamName,
    STeamPosition,
    GroupFilterstate
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/mentordetailQic",
      {
        userId,
        search,
        SEmail,
        SHackothonFinisher,
        STechnofundaAward,
        SGroup,
        SCommunityDuration,
        SIndustry,
        STeamName,
        STeamPosition,
        GroupFilterstate,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const MemberDetails = async (userId, teamname, currentActiveEvent) => {
    const response = await Axios.post(SERVER_API_URL + "/api/qic/teamname", {
      userId,
      teamname,
      currentActiveEvent,
    });
    verifyAuthorization(response);
    return response;
  };
  const StageAttendDetails = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/stageattenddetails",
      {
        userId,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const QICSemifinalist = async (stageId, userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/semifinalist",
      { stageId, userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const ShortlistStatusNextRound = async (userId, StageId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/shortliststatusnextround",
      {
        userId,
        StageId,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const QicUploadData = async (StageId, userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/qicuploaddata",
      {
        StageId,
        userId,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const ValidCertificateUser = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/validcertificateuser",
      {
        userId,
        value,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const TeamdetailsdataQic = async (userId, STeamName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/teamdetailQic",
      { userId, STeamName }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditdatauserQic = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditdatauserQic",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditdataMentorrQic = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditdatamentorQic",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const TeamEditDetailsData = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/teamNameEditGetData",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const DocumentdetailsdataQic = async (
    userId,
    filterTeamName,
    filterStageName,
    filtershortlist,
    filterEventName
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/docdetailQic",
      {
        userId,
        filterTeamName,
        filterStageName,
        filtershortlist,
        filterEventName,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const DocumentdetailsdataQicMisson4 = async (
    userId,
    filterTeamName,
    filterStageName,
    filtershortlist,
    filterEventName
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/docdetailQicm4",
      {
        userId,
        filterTeamName,
        filterStageName,
        filtershortlist,
        filterEventName,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const RegisterCheck = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/registercheck",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const RegisterMentor = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/RegisterMentor",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditTeamQic = async (userId, value) => {
    const response = await Axios.post(SERVER_API_URL + "/api/qic/EditTeamQic", {
      userId,
      value,
    });
    verifyAuthorization(response);
    return response;
  };
  const GetAdminName = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/GetAdminName",
      {
        userId,
        value,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditDocQic = async (value, value1, userId) => {
    const response = await Axios.post(SERVER_API_URL + "/api/qic/EditDocQic", {
      value,
      value1,
      userId,
    });
    verifyAuthorization(response);
    return response;
  };
  const EditDocDataQic = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditDocDataQic",
      {
        userId,
        value,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditSavedataQicMentor = async (
    userId,
    ValueCheck,
    emaildata,
    fnamedata,
    isPosition,
    mHackathonFinisher,
    mentorteam,
    backupMentorteam
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditsavedataQicMentor",
      {
        userId,
        ValueCheck,
        emaildata,
        fnamedata,
        isPosition,
        mHackathonFinisher,
        mentorteam,
        backupMentorteam,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditSavedataQic = async (
    userId,
    ValueCheck,
    telegramLink,
    teamName,
    teamMentor
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditsavedataQic",
      {
        userId,
        ValueCheck,
        telegramLink,
        teamName,
        teamMentor,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditSavedataQicTeam = async (
    userId,
    ValueCheck,
    telegramLink,
    teamName
    // teamMentor,
    // teamMentor2,
    // selectedAdmin
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditsavedataQicTeam",
      {
        userId,
        ValueCheck,
        telegramLink,
        teamName,
        // teamMentor,
        // teamMentor2,
        // selectedAdmin,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditsavedTeamQic = async (
    userId,
    ValueCheck,
    Week1,
    Week2,
    Week3,
    Week4,
    Week5,
    Week6,
    Week7,
    Week8,
    Team
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditsavedteamQic",
      {
        userId,
        ValueCheck,
        Week1,
        Week2,
        Week3,
        Week4,
        Week5,
        Week6,
        Week7,
        Week8,
        Team,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditsaveddocQic = async (
    userId,
    ValueCheck,
    Status,
    Team,
    Stageid,
    teamId,
    CuruserId
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditsaveddocQic",
      {
        userId,
        ValueCheck,
        Status,
        Team,
        Stageid,
        teamId,
        CuruserId,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const SaveCreateTeam = async (userId, createTeam) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/SaveCreateTeam",
      { userId, createTeam }
    );
    verifyAuthorization(response);
    return response;
  };
  const SaveCreateEvent = async (
    userId,
    CreateEvent,
    EventDesc,
    eventStartDate,
    registerStartDate,
    registerEndDate,
    numberofTeams,
    eventEndDate
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/SaveCreateEvent",
      {
        userId,
        CreateEvent,
        EventDesc,
        eventStartDate,
        registerStartDate,
        registerEndDate,
        numberofTeams,
        eventEndDate,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const SaveRenameTeam = async (userId, RenameTeam, OriginalTeamName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/SaveRenameTeam",
      { userId, RenameTeam, OriginalTeamName }
    );
    verifyAuthorization(response);
    return response;
  };

  const UserTeamDetails = async () => {
    const response = await Axios.get(SERVER_API_URL + "/api/teams");
    verifyAuthorization(response);
    return response;
  };
  const StageShortlistTeamDetails = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/teamshortlistlist",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const QicEventDetails = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/eventdetails",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const HandleDeactiveEvent = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/handledeactivate",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const HandleActiveEvent = async (
    userId,
    CurEventName,
    startDate,
    endDate,
    EstartDate,
    EendDate,
    ENoofTeams,
    QicEventDesc
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/handleactivate",
      {
        userId,
        CurEventName,
        startDate,
        endDate,
        EstartDate,
        EendDate,
        ENoofTeams,
        QicEventDesc,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const AllQicEventDetails = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/alleventdetails",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const QicEventDetailsSave = async (
    userId,
    CurEventName,
    startDate,
    endDate,
    EstartDate,
    EendDate,
    ENoofTeams,
    QicEventDesc
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/eventdetailssave",
      {
        userId,
        CurEventName,
        startDate,
        endDate,
        EstartDate,
        EendDate,
        ENoofTeams,
        QicEventDesc,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const EventName = async () => {
    const response = await Axios.get(SERVER_API_URL + "/api/qic/eventsname");
    verifyAuthorization(response);
    return response;
  };

  const QicStageDetailsSave = async (
    userId,
    startDate,
    endDate,
    valueCheck
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/savedstagedata",
      { userId, startDate, endDate, valueCheck }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditStageData = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/editstagedata",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditActiveEvent = async (userId, activestage, CurEventName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/editactiveevent",
      { userId, activestage, CurEventName }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditActiveEventForCertificate = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/editactiveeventcertificate",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const SendMessageToAdmins = async (
    emails,
    mobilenumber,
    category,
    subject,
    content,
    attachment,
    userId,
    categoryIDs,
    helpstatus
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/Sendmessagetoadmins",
      {
        emails,
        mobilenumber,
        category,
        subject,
        content,
        attachment,
        userId,
        categoryIDs,
        helpstatus,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const AddedQicMentor = async (
    userId,
    auEmail,
    auHackothonFinisher,
    auTechnofundaaward,
    auCommunityDuration,
    auIndustry,
    currentActiveEvent
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/addedqicmentor",
      {
        userId,
        auEmail,
        auHackothonFinisher,
        auTechnofundaaward,
        auCommunityDuration,
        auIndustry,
        currentActiveEvent,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const AddedQicUser = async (
    userId,
    auEmail,
    auHackothonFinisher,
    auTechnofundaaward,
    auCommunityDuration,
    auIndustry,
    currentActiveEvent
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/addedqicuser",
      {
        userId,
        auEmail,
        auHackothonFinisher,
        auTechnofundaaward,
        auCommunityDuration,
        auIndustry,
        currentActiveEvent,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const DeleteSelectedData = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/deleteuserval",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const DeleteSelectedDatateam = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/deleteuservalteam",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const DeleteSelectedDataMentor = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/deleteuservalmentor",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };

  const BusinessRanking_Quiz_Questions = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/brquiz", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };
  const BusinessRanking_Quiz_Questions_Answer = async (data, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/brquizanswer",
      {
        data,
        userId,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const BusinessRanking_Quiz_Questions_Answer_Check = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/brquizanswercheck",
      {
        userId,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Voting_Insert = async (userId, teamid, type, isType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/votinginsert",
      {
        userId,
        teamid,
        type,
        isType,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const QicVotingDetails = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/votingdetails",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditVotingQic = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/editvotingqic",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const QicVotingDetailsSave = async (
    userId,
    voteLimitSemifinaledit,
    likepointsemifinaledit,
    dislikePointSemifinaledit,
    startDateEdit,
    endDateEdit
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/votingdetailssave",
      {
        userId,
        voteLimitSemifinaledit,
        likepointsemifinaledit,
        dislikePointSemifinaledit,
        startDateEdit,
        endDateEdit,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const QicVotingDetailsSaveFinal = async (
    userId,
    voteLimitfinaledit,
    likepointfinaledit,
    dislikepointfinaledit,
    startDateEdit,
    endDateEdit
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/votingdetailssavefinal",
      {
        userId,
        voteLimitfinaledit,
        likepointfinaledit,
        dislikepointfinaledit,
        startDateEdit,
        endDateEdit,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const ProfileTeamDetails = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/teamdetailprofile",
      {
        userId,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const TechnoDashboard = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/technodashboard",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const EventJoin = async (userId) => {
    const response = await Axios.post(SERVER_API_URL + "/api/qic/eventjoin", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const CheckUserRole = async (userId, NId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/userrole",
      {
        userId,
        NId,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const OnboardingCheckList_Added = async (
    userId,
    selectedMark,
    title_id,
    sub_id
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/OnBoarding/OnboardingCheckList_Added",
      { userId, selectedMark, title_id, sub_id }
    );
    verifyAuthorization(response);
    return response;
  };
  const OnboardingCheckList_Delete_OR_Edit = async (
    userId,
    selectedMark,
    title_id,
    sub_id
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/OnBoarding/OnboardingCheckList_Delete_OR_Edit",
      { userId, selectedMark, title_id, sub_id }
    );
    verifyAuthorization(response);
    return response;
  };

  return (
    <JWTContext.Provider
      value={{
        ...state,
        OnboardingCheckList_Added,
        OnboardingCheckList_Delete_OR_Edit,
        stockalertviewdata,
        StockAlertList,
        stockalertUpdate,
        stockalertDelete,
        stockInsert,
        stocklist,
        Displayresponse,
        UpdateResponse,
        GetcategorylistTool,
        GetdataHelpManagement,
        updateCoreAudit,
        ProcessUpdate,
        CheckStatusOfLearning,
        DeleteLearningHistory,
        InsertLearningHistory,
        Alloccupationlist,
        CheckTotalCountOfLearning,
        GetLearing,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        verifyotp,
        newPassword,
        Profiledata,
        registerOtpVerify,
        SociallinkView,
        updateSociallink,
        AllUsers,
        EventReport,
        Addfriend,
        Removefriend,
        MyFollower,
        MyFriends,
        MyGrouplist,
        Statistics,
        MemberProfile,
        charitylist,
        watchlist,
        charitylistdata,
        deletecharitydata,
        Insertcharitylist,
        Insertwatchlist,
        weekdata1,
        weekly,
        watchlistdata,
        vivekwatchlistdata,
        weeklist,
        DeleteWatchlist,
        Coreauditcompanylist,
        Coreauditdatalist,
        insertCoreAudit,
        deleteCoreAudit,
        WeeklyRitualsQuestions,
        WeeklyRitualsAnswer,
        WeeklyRitualsAnswerCheck,
        QuarterlyRitualsQuestions,
        QuarterlyRitualsAnswer,
        QuarterlyRitualsAnswerCheck,
        Editcharitydata,
        Resourceslist,
        ResearchList,
        TemplateList,
        TechnoList,
        ApplicationsList,
        Quantum_Quiz_Questions,
        Quantum_insertdata,
        checkuserIddata,
        checkanswerdata,
        Qscore,
        Email,
        Rewards_chart,
        TopTwentyuserDetailsBaseOnRewaerdPoint,
        TrendingCorePortfolio,
        TrendingSatelliteWatchlist,
        RewardsPoint,
        habits,
        habitchart,
        CheckStatusOfHabit,
        GetHabitsNamelist,
        InsertHabit,
        Pastevent,
        Futurevent,
        Todayevent,
        GetAttendance,
        InsertAttendance,
        updateVideoLink,
        CalendarEventsList,
        AttendanceChartData,
        profilevisibilityInsert,
        profilevisibility,
        UserDetails,
        PropertyDetails,
        LeadsDetails,
        LeadsDetailsDashboard,
        Editdatauser,
        EditSavedata,
        EditSavedataProperty,
        Adddatauser,
        AdddataProperty,
        AdddataLeads,
        Csvreport,
        CharityReport,
        Watchlist,
        CoreAuditReportdata,
        LoginReport,
        NotloginReportdata,
        QuantumQuizdata,
        updateEvent,
        DeleteSchedule,
        ListofPropertyOwner,
        ListofProperty,
        DeletePropertyTable,
        DeleteUserTable,
        DeletePropertyLeads,
        GetEventsList,
        AddEvent,
        AlertReport,
        GroupnameList,
        EventList,
        GetcategoryWithoutLogin,
        ContactInsertWithoutLogin,
        Getcategory,
        ContactInsert,
        corewatchlist,
        coreInsertwatchlist,
        corewatchlisthistory,
        DeleteCoreWatchlist,
        EditCoreWatchlistgetdata,
        GetHabitsList,
        Gethabitsdata,
        MonthlyLineChart,
        handleChangedeletedata,
        CatcusDeleteCoreWatchlist,
        Catcuswatchlisthistory,
        GetAllCompanyforStockAlert,
        CreateStockalert,
        GetStockalertCount,
        GetStockalertList,
        StockAlertDelete,
        UpdateStockAlert,
        GetMarketchartdata,
        GetMarketcategory,
        Gamefications,
        Onboarding,
        Marketdata,
        Insertedata,
        CoreauditCashdatalist,
        UpdateCoreauditCashdata,
        DeleteAllSchedule,
        UserDetailsQic,
        MentorDetailsQic,
        MemberDetails,
        TeamdetailsdataQic,
        EditdatauserQic,
        EditdataProperty,
        EditdataMentorrQic,
        TeamEditDetailsData,
        RegisterCheck,
        EditTeamQic,
        RegisterMentor,
        GetAdminName,
        EditSavedataQic,
        EditSavedataQicTeam,
        EditSavedataQicMentor,
        EditsavedTeamQic,
        SaveCreateTeam,
        SaveRenameTeam,
        UserTeamDetails,
        QicEventDetails,
        EventName,
        QicEventDetailsSave,
        AllQicEventDetails,
        HandleDeactiveEvent,
        HandleActiveEvent,
        SaveCreateEvent,
        DocumentdetailsdataQic,
        DocumentdetailsdataQicMisson4,
        EditDocQic,
        EditDocDataQic,
        EditsaveddocQic,
        StageShortlistTeamDetails,
        StageAttendDetails,
        ShortlistStatusNextRound,
        QicUploadData,
        ValidCertificateUser,
        QICSemifinalist,
        QicStageDetailsSave,
        EditStageData,
        EditActiveEvent,
        EditActiveEventForCertificate,
        SendMessageToAdmins,
        DeleteSelectedData,
        DeleteSelectedDatateam,
        DeleteSelectedDataMentor,
        AddedQicUser,
        AddedQicMentor,
        BusinessRanking_Quiz_Questions,
        BusinessRanking_Quiz_Questions_Answer,
        BusinessRanking_Quiz_Questions_Answer_Check,
        Voting_Insert,
        QicVotingDetails,
        EditVotingQic,
        QicVotingDetailsSave,
        QicVotingDetailsSaveFinal,
        ProfileTeamDetails,
        TechnoDashboard,
        EventJoin,
        CheckUserRole,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
