import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import { element } from "prop-types";
// import UserDashboard from "Crm/dashboard/user";

// User Dashboard
const UserDashboard = Loadable(lazy(() => import("Crm/dashboard/user")));

const Profile = Loadable(lazy(() => import("Collab-community-views/profile")));

const Users = Loadable(lazy(() => import("Adminviews/dashboard/Users")));

const User_Management = Loadable(
  lazy(() => import("Collab-community-views/dashboard/User_management"))
);
const PropertyManagement = Loadable(
  lazy(() => import("Collab-community-views/dashboard/Property_Management"))
);
const LeadsManagement = Loadable(
  lazy(() => import("Collab-community-views/dashboard/LeadsManagement"))
);

const Help_Response = Loadable(
  lazy(() => import("Collab-community-views/dashboard/HelpResponse"))
);
const Help_Management = Loadable(
  lazy(() => import("Adminviews/dashboard/Help_management"))
);

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/Profile",
      element: <Profile />,
    },
    {
      path: "/Reports",
      element: <Users />,
    },
    {
      path: "/User_Management",
      element: <User_Management />,
    },
    {
      path: "/PropertyManagement",
      element: <PropertyManagement />,
    },
    {
      path: "/LeadsManagement",
      element: <LeadsManagement />,
    },
    {
      path: "/HelpResponse",
      element: <Help_Response />,
    },
    {
      path: "/Help_Management",
      element: <Help_Management />,
    },

    {
      path: "/user/dashboard",
      element: <UserDashboard />,
    },
  ],
};

export default MainRoutes;

// "v=spf1 include:_spf.mail.hostinger.com ~all"