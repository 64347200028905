// NotFound.js
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./style.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="Card404">
      <h1>Page Not Found</h1>
      <p>Sorry, we could not find the page</p>
      <Link to="/" className="btn404">
        <span>
          <ArrowBackIcon />
        </span>
        <span>Go to Login</span>
      </Link>
    </div>
  );
};

export default NotFound;
