// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconDashboard,
  IconUser,
  IconLayoutDashboard,
  IconDeviceAnalytics,
  IconClipboardCheck,
  IconPictureInPicture,
  IconForms,
  IconChartArcs,
  IconDatabaseExport,
  IconApps,
  IconCalendarEvent,
  IconUsers,
  IconUserX,
  IconBuildingEstate,
  IconFileSpreadsheet,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconLayoutDashboard,
  IconDeviceAnalytics,
  IconClipboardCheck,
  IconPictureInPicture,
  IconForms,
  IconChartArcs,
  IconDatabaseExport,
  IconApps,
  IconUsers,
  IconUserX,
  IconCalendarEvent,
  IconBuildingEstate,
  IconFileSpreadsheet,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Admin = {
  id: "Admin Dashboard",
  title: <FormattedMessage id="Admin Dashboard" />,
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: "Manage users",
      title: <FormattedMessage id="Manage Users" />,
      type: "item",
      url: "/User_Management",
      icon: icons.IconUsers,
      breadcrumbs: false,
    },
    {
      id: "manage property",
      title: <FormattedMessage id="Manage Property" />,
      type: "item",
      url: "/PropertyManagement",
      icon: icons.IconBuildingEstate,
      breadcrumbs: false,
    },
    {
      id: "Manage leads",
      title: <FormattedMessage id="Manage Leads" />,
      type: "item",
      url: "/LeadsManagement",
      icon: icons.IconFileSpreadsheet,
      breadcrumbs: false,
    },
  ],
};

export default Admin;
