import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 1 routing
const AuthLogin1 = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));
const AuthRegister1 = Loadable(lazy(() => import('views/pages/authentication/authentication/Register')));
const AuthForgotPassword1 = Loadable(lazy(() => import('views/pages/authentication/authentication/ForgotPassword')));
const AuthCheckMail1 = Loadable(lazy(() => import('views/pages/authentication/authentication/CheckMail')));
const AuthResetPassword1 = Loadable(lazy(() => import('views/pages/authentication/authentication/ResetPassword')));
const AuthCodeVerification1 = Loadable(lazy(() => import('views/pages/authentication/authentication/CodeVerification')));

// landing & contact-us routing
const PagesContactUS = Loadable(lazy(() => import('views/pages/contact-us')));
const EmailVerify = Loadable(lazy(() => import('Collab-community-views/dashboard/Email_verify')));
const ContactUS = Loadable(lazy(() => import('views/pages/authentication/authentication/Contact')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pages/login/login1',
            element: <AuthLogin1 />
        },
        {
            path: '/pages/register/register1',
            element: <AuthRegister1 />
        },
        {
            path: '/pages/forgot-password/forgot-password1',
            element: <AuthForgotPassword1 />
        },
        {
            path: '/pages/check-mail/check-mail1',
            element: <AuthCheckMail1 />
        },
        {
            path: '/reset-password',
            element: <AuthResetPassword1 />
        },
        {
            path: '/pages/code-verification/code-verification1',
            element: <AuthCodeVerification1 />
        },
		{
            path:'/EmailVerify/:GetIdFromUserOnClickEmailLink',
            element:<EmailVerify />
        },
        {
            path:'/Contact',
            element:<ContactUS />
        }
    ]
};

export default AuthenticationRoutes;
