import Profile from "./Profile";
import Admin from "./Admin";

// ==============================|| MENU ITEMS ||============================== //
const Usersid = window.localStorage.getItem("users");

let menuItems = {};
menuItems = {
  items: [Profile],
};
if (Usersid) {
  const { role_id } = JSON.parse(Usersid);
  if (role_id !== 1) {
    menuItems = {
      items: [Profile],
    };
  }
  if (role_id === 1) {
    menuItems = {
      items: [Admin],
    };
  }
}

export default menuItems;
